import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import {Form} from '@evag/ec-react';

import '@evag/ec-react/dist/main.css';

import CampaignFeedback from '../components/CampaignFeedback';
import CampaignForm from '../components/CampaignForm';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function render({data}) {
  CampaignForm.channelId = data.site.meta.channel;

  return (
    <Layout>
      <SEO title={data.site.meta.title} />

      <Hero
        background={data.hero.childImageSharp.fluid}
        emancipa={data.emancipa.childImageSharp.fluid}
        title={data.site.meta.title}
        description={data.site.meta.description}
        channel={data.site.meta.channel}
      />

      <main className="main">
        <div className="wrap">
          <p>Como se sabe, o avanço da Covid-19 no Brasil representa uma tragédia para todos, mas não atinge todas as pessoas da mesma forma. Seja pela desigualdade social, pela desigualdade de gênero e pelo racismo estruturantes da sociedade brasileira, seja pela postura irresponsável de muitos governos – em especial do governo Bolsonaro -, desenvolvem-se políticas de morte que atingem com muito mais violência os pobres, as mulheres, a negritude e os moradores das periferias e áreas rurais.</p>

          <p>Além deles, uma gama mais ampla de trabalhadoras e trabalhadores que antes figuravam em camadas médias se vêm arrastados também para essa realidade trágica diante da destruição de postos de trabalho, da redução da jornada e salários, da precarização do trabalho e do avanço da pobreza.</p>

          <h2>Por que uma renda permanente?</h2>

          <p>A adoção da renda básica permanente permitiria, em primeiro lugar, defender a vida dos mais pobres tendo em vista que os estudos apontam que, mesmo passada a pandemia, ainda haverá forte crise econômica. Mas não só, a medida também poderia forçar uma mudança nas práticas mais aviltantes de contratação nas empresas porque daria uma alternativa ao mercado de trabalho àqueles que por falta de um piso mínimo de renda têm que se submeter a trabalhos indignos e precários. O mesmo pode-se dizer a muitos outros trabalhadores como os jovens que buscam o primeiro emprego ou a artistas e trabalhadores da cultura, por exemplo. No caso das famílias monoparentais, especialmente comandadas por mulheres, seria uma forma de proteção à vida de crianças e dependentes. Defendemos que este direito a uma renda básica comece pelos grupos sociais mais atingidos pela crise mas que seja visto como política universal que deve chegar à toda população.</p>

          <h2>Como seria seu financiamento?</h2>

          <p>A renda básica permanente só faz sentido se não reforçar a desigualdade secular do Brasil. Assim, propostas de renda básica que não a vejam como um direito e impliquem na extinção de outros programas ou direitos sociais como estão sendo aventadas por membros do governo federal ou do Congresso, não servem. Defendemos que a renda seja paga a partir da instituição do imposto sobre as grandes fortunas e da correção da injustiça tributária histórica do país onde os mais pobres, por meio do consumo e da renda do trabalho, pagam muito mais impostos que os mais ricos, tendo em vista a baixa taxação sobre as rendas do capital e os patrimônios. É perfeitamente possível!</p>

          <p>Apoie essa ideia! Divulgue esta petição e nos ajude a construir um amplo movimento em defesa da Renda Básica Permanente no Brasil!</p>
        </div>
      </main>

      <div id="sign" className="signatures">
        <div className="wrap">
          <h2 className="signatures-title">Assine a petição</h2>

          <Form
            forms={[
              CampaignForm,
              {
                ...CampaignFeedback,
                title: data.site.meta.title,
                url: data.site.meta.url,
              },
            ]}
          />
        </div>
      </div>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    emancipa: file(relativePath: { eq: "emancipa.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    hero: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
